import React, { Component, Suspense } from "react";
const FullParticipant = React.lazy(() => import("./FullParticipant"));
const FullAdmin = React.lazy(() => import("./FullAdmin"));
import axios from "axios";
import AppContext from "../../AppContext";
import { Container, Row, Col, Card, Button } from "reactstrap";
const ForceChangePassword = React.lazy(() => import("./ForceChangePassword"));
const TermsAndConditions = React.lazy(() => import("./TermsAndConditions"));
const TermsAndConditionsMS = React.lazy(() => import("./TermsAndConditionsMS"));
import SessionClose from "./SessionClose";
import MS_Header from "../../components/Header/MS_Header";
import MS_Footer from "../../components/Footer/MS_Footer";
import {
    config,
    cypherkey,
    cypheriv,
    algorithm,
} from "../../constants/api_host";
const MFA = React.lazy(() => import("./MFA"));
const MFAOptions = React.lazy(() => import("./MFAOptions"));
import ModalControl from "../../components/Controls/ModalControl";

import Cookies from "universal-cookie";

const cookies = new Cookies();

const DEFAULT_QUERY_connectUser = "AdminMain/connectUser";
const DEFAULT_QUERY_GetMFAType = "AdminMFA/GetMFAType";
const DEFAULT_QUERY_Logout = "AdminMain/LogOut";

class Full extends Component {
    constructor(props) {
        super(props);

        this.modal = React.createRef();
        this.messageError = "";
        this.MFATypes = [];
        this.Email = "";
        this.state = {
            currentProgress: 0,
            currentPage: "",
            userData: {
                groupID: 0,
                clientID: 0,
                acceptTerms: true,
                acceptMSTerms: true,
                passwordHasToBeChanged: false,
            },
            MFAType: 0,
            firstTime: false,
        };

        this.getPageData = this.getPageData.bind(this);
        this.displayError = this.displayError.bind(this);
        this.getCookie = this.getCookie.bind(this);
        this.handler_changeMFAType = this.handler_changeMFAType.bind(this);
        this.handler_Next = this.handler_Next.bind(this);
        this.handler_Cancel = this.handler_Cancel.bind(this);
        this.clickonLogout = this.clickonLogout.bind(this);
        this.onBackHome = this.onBackHome.bind(this);
        this.getErrorMessage = this.getErrorMessage.bind(this);
    }

    componentDidMount() {
        this.ShowActualError = ["dev-nqdc", "qa-nqdc", "localhost"].includes(top?.location?.hostname?.split('.')?.[0]?.toLowerCase());
        document.body.classList.toggle("sidebar-hidden");
        this.setState({ firstTime: true });
    }

    static contextType = AppContext;

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps !== this.props || nextState !== this.state) {
            if (nextState.firstTime == true) {
                this.setState({ firstTime: false });
                this.getPageData();
            } else if (
                nextState.userData !== this.state.userData ||
                nextState.currentProgress != this.state.currentProgress
            ) {
            }
            return true;
        }
        return false;
    }

    getCookie() {
        let user = "";
        let result = this.context.API_TOKEN.split(".");
        if (result.length > 1) {
            user = result[1];
        }
        let cookie = cookies.get("afs-" + user);
        return cookie;
    }

    LogOutRedirect() {
        window.location.replace(this.context.login_url + "action=logoff");
    }

    SessionCloseRedirect() {
        window.location.replace(this.context.login_url + "action=sessionclose");
    }

    onBackHome() {
        this.clickonLogout(true);
    }

    async clickonLogout(sessionRedirect = false) {
        axios
            .all([
                axios.get(
                    this.context.API_URL_HOST +
                    DEFAULT_QUERY_Logout +
                    "?" +
                    this.context.encrypt(this.context.API_TOKEN)
                ),
            ])
            .then(
                axios.spread((LogoutMsg) => {
                    if (LogoutMsg.data.errorMessage != 0) {
                        this.displayError(
                            LogoutMsg.data.msgError,
                            LogoutMsg.data.errorMessage
                        );
                    } else {
                        if (sessionRedirect) {
                            window.location.replace(this.context.login_url);
                            return;
                        }
                        this.setState({
                            currentPage: "LogOut",
                        });
                    }
                })
            )
            .catch((error) => {
                if (sessionRedirect) {
                    window.location.replace(this.context.login_url);
                    return;
                }
                this.displayError(0, "", error);
            });
    }

    handler_Cancel(strError) {
        this.displayError(strError, 10501, undefined, this.clickonLogout);
        //this.messageError = strError;
        //this.clickonLogout();
    }

    handler_Next() {
        let currentProgress = this.state.currentProgress + 1;
        let currentPage = "";
        if (currentProgress == 3) {
            if (this.state.userData.passwordHasToBeChanged) {
                currentPage = "ChangePassword";
            } else {
                currentPage = "TermsAndConditions";
                currentProgress = 4;
            }
        }
        if (currentProgress == 4) {
            if (!this.state.userData.acceptTerms) {
                currentPage = "TermsAndConditions";
            } else {
                currentPage = "TermsAndConditionsMS";
                currentProgress = 5;
            }
        }
        if (currentProgress == 5) {
            if (
                this.state.userData.acceptMSTerms ||
                this.state.userData.groupID == 2
            ) {
                currentPage = "Welcome";
                currentProgress = 6;
            } else {
                currentPage = "TermsAndConditionsMS";
            }
        }

        this.setState({
            currentPage: currentPage,
            currentProgress: currentProgress,
        });
    }

    handler_changeMFAType(mfaType) {
        if (mfaType == -1) {
            this.setState({
                currentPage: "MFAOptions",
                currentProgress: 1,
                MFAType: 0,
            });
        } else {
            this.setState({
                currentPage: "MFA",
                currentProgress: 2,
                MFAType: mfaType,
            });
        }
    }

    changeSubMenuIndex(page) {
        this.setState({
            currentPage: page,
            currentProgress: -1,
        });
    }

    getErrorMessage(axiosError) {
        const url = axiosError.config?.url;
        let failedUrl = '';
        if (url.includes("rest/Admin")) {
            failedUrl = url?.substring(url?.indexOf("rest/Admin"), url?.indexOf("?"));
        } else {
            failedUrl = url?.substring(url?.indexOf("rest/Participant"), url?.indexOf("?"));
        }

        const messageError = this.ShowActualError ? `${failedUrl}. ${axiosError.message}` : `An error occurred with your request. Please retry again later.`;
        return messageError;
    }

    displayError(msgError, errorMessage, axiosError, okFunction) {
        if (axiosError !== undefined) {
            console.log("AXIOS ERROR: ", axiosError);
            if (axiosError.response?.status != 405) {
                this.modal?.current?.togglemodalOK("Error", this.getErrorMessage(axiosError), 2);
                return;
            }
            if (axiosError.response) {
                /*
                 * The request was made and the server responded with a
                 * status code that falls out of the range of 2xx
                 */
                this.changeSubMenuIndex("SessionClose");
            } else if (axiosError.request) {
                /*
                 * The request was made but no response was received, `error.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */
                this.changeSubMenuIndex("SessionClose");
                //this.modal.current.togglemodalOK("Error", axiosError.request);
            } else {
                // Something happened in setting up the request and triggered an Error
                this.changeSubMenuIndex("SessionClose");
                //this.modal.current.togglemodalOK("Error", axiosError);
            }
        } else {
            let severity =
                errorMessage === undefined || errorMessage == 0
                    ? null
                    : Math.floor((errorMessage - 10000) / 100);
            if (
                msgError === undefined ||
                errorMessage === undefined ||
                msgError == ""
            ) {
                if (okFunction !== undefined) {
                    okFunction();
                } else {
                    this.changeSubMenuIndex("SessionClose");
                }
            } else {
                //this.messageError = msgError;
                //this.changeSubMenuIndex("SessionClose");
                this.modal.current.togglemodalOK(
                    "Message",
                    msgError,
                    severity,
                    okFunction
                );
            }
        }
        return;
    }

    async getPageData() {
        axios
            .all([
                axios.get(
                    this.context.API_URL_HOST +
                    DEFAULT_QUERY_connectUser +
                    "?" +
                    this.context.encrypt(this.context.API_TOKEN)
                ),
            ])
            .then(
                axios.spread((info) => {
                    if (info.data.errorMessage != 0) {
                        this.displayError(info.data.msgError, info.data.errorMessage);
                    } else {
                        this.getMFAData();
                    }
                })
            )
            .catch((error) => {
                this.displayError(0, "", error);
            });
    }

    async getMFAData() {
        let cookie = this.getCookie();
        if (cookie === undefined) cookie = "";
        //this.setState({ isLoading: true });
        axios
            .all([
                axios.get(
                    this.context.API_URL_HOST +
                    DEFAULT_QUERY_GetMFAType +
                    "?" +
                    this.context.encrypt("cookie=" + cookie + this.context.API_TOKEN)
                ),
            ])
            .then(
                axios.spread((mfa) => {
                    let info = mfa.data.rows;
                    if (mfa.data.errorMessage != 0) {
                        this.displayError(mfa.data.msgError, mfa.data.errorMessage);
                    } else {
                        let currentPage = "";
                        let currentProgress = 0;
                        let MFAType = 0;
                        if (this.context.API_TOKEN.split(".").length == 4) {
                            //Participant view doesnt do MFA, change pwd or T&C
                            currentPage = "Welcome";
                            currentProgress = 6;
                        } else if (mfa.data.returnCode == 0) {
                            currentProgress = 3;
                            currentPage = "ChangePassword";
                        } else if (mfa.data.returnCode == -1) {
                            this.MFATypes = [];
                            this.Email = null;
                            currentPage = "MFAOptions";
                            currentProgress = 1;
                        } else if (mfa.data.returnCode == 100) {
                            this.MFATypes = mfa.data.rows;
                            this.EMail = mfa.data.returnMessage;
                            currentPage = "MFAOptions";
                            currentProgress = 1;
                        } else if (mfa.data.returnCode != 0) {
                            this.MFATypes = mfa.data.rows;
                            this.EMail = mfa.data.returnMessage;
                            MFAType = mfa.data.returnCode;
                            currentPage = "MFA";
                            currentProgress = 2;
                        }
                        if (
                            currentProgress == 3 &&
                            !info.passwordHasToBeChanged
                        ) {
                            currentPage = "TermsAndConditions";
                            currentProgress = 4;
                        }
                        if (currentProgress == 4 && info.acceptTerms) {
                            currentPage = "TermsAndConditionsMS";
                            currentProgress = 5;
                        }
                        if (
                            currentProgress == 5 &&
                            (info.acceptMSTerms || info.groupID == 2)
                        ) {
                            currentPage = "Welcome";
                            currentProgress = 6;
                        }
                        this.setState({
                            currentPage: currentPage,
                            currentProgress: currentProgress,
                            MFAType: MFAType,
                            userData: info,
                        });
                    }
                })
            )
            .catch((error) => {
                this.displayError(0, "", error);
            });
    }

    render() {
        if (this.state.currentPage == "LogOut") {
            this.LogOutRedirect();
        } else if (this.state.currentPage == "SessionClose") {
            return (
                <div className="app">
                    <MS_Header
                        groupID={this.state.userData.groupID}
                        clientID={this.state.userData.clientID}
                    />
                    <SessionClose backHome={this.onBackHome} />
                    <MS_Footer displayError={this.displayError} />
                </div>
            );
            //this.SessionCloseRedirect();
        } else if (this.state.currentPage == "MFAOptions") {
            return (
                <div className="app">
                    <MS_Header
                        groupID={this.state.userData.groupID}
                        clientID={this.state.userData.clientID}
                    />
                    <ModalControl ref={this.modal} />
                    <Suspense fallback={<div></div>}>
                        <MFAOptions
                            isAdmin={true}
                            MFAType={this.state.MFAType}
                            EMail={this.EMail}
                            handler_Cancel={this.handler_Cancel}
                            handler_Next={this.handler_Next}
                            handler_changeMFAType={this.handler_changeMFAType}
                            displayError={this.displayError}
                            MFATypes={this.MFATypes}
                        />
                    </Suspense>
                    <MS_Footer displayError={this.displayError} />
                </div>
            );
        } else if (this.state.currentPage == "MFA" && this.state.MFAType > 0) {
            return (
                <div className="app">
                    <MS_Header
                        groupID={this.state.userData.groupID}
                        clientID={this.state.userData.clientID}
                    />
                    <ModalControl ref={this.modal} />
                    <Suspense fallback={<div></div>}>
                        <MFA
                            isAdmin={true}
                            MFAType={this.state.MFAType}
                            EMail={this.EMail}
                            handler_Cancel={this.handler_Cancel}
                            handler_Next={this.handler_Next}
                            handler_changeMFAType={this.handler_changeMFAType}
                            displayError={this.displayError}
                            MFATypes={this.MFATypes}
                        />
                    </Suspense>
                    <MS_Footer displayError={this.displayError} />
                </div>
            );
        } else if (this.state.currentPage == "ChangePassword") {
            return (
                <div className="app">
                    <ModalControl ref={this.modal} />
                    <MS_Header
                        groupID={this.state.userData.groupID}
                        clientID={this.state.userData.clientID}
                    />
                    <Suspense fallback={<div></div>}>
                        <ForceChangePassword
                            displayError={this.displayError}
                            handler_Cancel={this.handler_Cancel}
                            handler_Next={this.handler_Next}
                            isAdmin={this.state.userData.groupID != 2}
                        />
                    </Suspense>
                    <MS_Footer displayError={this.displayError} />
                </div>
            );
        } else if (this.state.currentPage == "TermsAndConditions") {
            return (
                <div className="app">
                    <Suspense fallback={<div></div>}>
                        <TermsAndConditions
                            displayError={this.displayError}
                            handler_Cancel={this.handler_Cancel}
                            handler_Next={this.handler_Next}
                        />
                    </Suspense>
                </div>
            );
        } else if (this.state.currentPage == "TermsAndConditionsMS") {
            return (
                <div className="app">
                    <Suspense fallback={<div></div>}>
                        <TermsAndConditionsMS
                            displayError={this.displayError}
                            handler_Cancel={this.handler_Cancel}
                            handler_Next={this.handler_Next}
                        />
                    </Suspense>
                </div>
            );
        } else if (this.state.userData.groupID == 0) {
            return (
                <div className="app">
                    <ModalControl ref={this.modal} />
                    <MS_Header
                        groupID={this.state.userData.groupID}
                        clientID={this.state.userData.clientID}
                    />
                    <div className="app-body">
                        <main className="main">
                            <Container>
                                <center>
                                    <h3
                                        className="loading-ms"
                                        style={{ marginTop: "200px", marginBottom: "200px" }}
                                    >
                                        Loading Plan Information...
                                    </h3>
                                </center>
                            </Container>
                        </main>
                    </div>
                    <MS_Footer displayError={this.displayError} />
                </div>
            );
        } else if (this.state.userData.groupID == 1) {
            return (
                <div className="app">
                    <ModalControl ref={this.modal} />
                    <Suspense fallback={<div></div>}>
                        <FullAdmin
                            initMainMenuIndex={this.props.menu}
                            initSubMenuIndex={this.props.subMenu}
                            extraParameter={this.props.extraParameter}
                            // extraParameter={
                            //   this.props.subMenu == "Undo/Redo" ? "mode=1" : undefined
                            // }
                            groupID={this.state.userData.groupID}
                            clientID={this.state.userData.clientID}
                            displayError={this.displayError}
                        />
                    </Suspense>
                </div>
            );
        } else if (this.state.userData.groupID == 2) {
            return (
                <div className="app">
                    <ModalControl ref={this.modal} />
                    <Suspense fallback={<div></div>}>
                        <FullParticipant
                            groupID={this.state.userData.groupID}
                            clientID={this.state.userData.clientID}
                            displayError={this.displayError}
                        />
                    </Suspense>
                </div>
            );
        } else if (this.state.userData.groupID == 3) {
            //Super-user client update page... pending
            return (
                <div className="app">
                    <ModalControl ref={this.modal} />
                    <MS_Header
                        groupID={this.state.userData.groupID}
                        clientID={this.state.userData.clientID}
                    />
                    <div className="app-body">
                        <main className="main">
                            <Container>
                                <center>
                                    <h3
                                        className="loading-ms"
                                        style={{ marginTop: "200px", marginBottom: "200px" }}
                                    >
                                        User Type not implemented
                                    </h3>
                                </center>
                            </Container>
                        </main>
                    </div>
                    <MS_Footer displayError={this.displayError} />
                </div>
            );
        } else {
            return (
                <div className="app">
                    <ModalControl ref={this.modal} />
                    <MS_Header
                        groupID={this.state.userData.groupID}
                        clientID={this.state.userData.clientID}
                    />
                    <div className="app-body">
                        <main className="main">
                            <Container>
                                <center>
                                    <h3
                                        className="loading-ms"
                                        style={{ marginTop: "200px", marginBottom: "200px" }}
                                    >
                                        Invalid User Type
                                    </h3>
                                </center>
                            </Container>
                        </main>
                    </div>
                    <MS_Footer displayError={this.displayError} />
                </div>
            );
        }
    }
}

export default Full;
